import * as React from 'react'
import { QueryClientProvider, QueryClient } from 'react-query'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './components/Home.js'
import { appContainerElementId } from './utils/constants'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
const queryClient = new QueryClient()
export function App(props) {
  // React.useEffect(() => {
  //   const modalContainerEl = document.getElementById('banquetPortalsContainer')
  //   if (modalContainerEl) {
  //     modalContainerEl.setAttribute('data-security-portal', 'true')
  //   }
  // }, [])
  return (
    <QueryClientProvider client={queryClient}>
      <SnackBarProvider domRoot='security-portal-spa'>
      <div className='p-4 text-color-link' id={appContainerElementId}>
        <Router>
          <Routes>
            <Route path='/:repositoryId/security/' element={<Home />} />
          </Routes>
        </Router>
      </div>
      </SnackBarProvider>
    </QueryClientProvider>
  )
}
