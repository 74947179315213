import React from 'react'
import { useParams } from 'react-router-dom'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import { HelpIcon } from '@toasttab/buffet-pui-icons'
import {
  LabelledToggleSwitchField
} from '@toasttab/buffet-pui-forms'
import NullableTextInputField from '../fields/NullableTextInputField'

const SnykConfig = () => {
  {
    const { repositoryId } = useParams()
    return (
      <CardContainer className='h-64 col-span-1'>
        <div className='flex flex-row mb-4'>
          <div className='flex-grow font-semibold type-large text-default'>
            Snyk
          </div>
          <InfoTooltip
            icon={<HelpIcon />}
            placement='auto'
          >
            Enable or Disable Snyk
          </InfoTooltip>
          <LabelledToggleSwitchField
            name='artifactConfiguration.snykEnabled'
            containerClassName='md:flex'
          />
        </div>
        <div className='mt-1 type-default text-secondary'>
          <NullableTextInputField
            containerClassName='flex-grow'
            name='artifactConfiguration.snykProjectPrefix'
            label='Project Prefix'
            helperText='This should be the repo name but might be different.'
            placeholder={repositoryId}
          />
        </div>
      </CardContainer>
    )
  }

}

export default SnykConfig
