import React from 'react'
import { useFormikContext, getIn } from 'formik'
import { TextInputField } from '@toasttab/buffet-pui-forms'

const JiraProjectsField = ((props) => {
  const { setFieldValue, values } = useFormikContext()

  const getValue = () => {
    const formikVal = getIn(values, props.name)
    return (formikVal ?? []).map((item) => item.project).join(',')
  }

  const setValue = (inputVal) => {
    const val = inputVal.split(/,\s*/).map((s) => s.trim()).filter((s) => s !== "")

    if (inputVal.length === 0) {
      setFieldValue(props.name, null)
    } else {
      if (inputVal[inputVal.length - 1] === ",") val.push("");
      setFieldValue(props.name, val.map((item) => ({project: item, source: 'manual'})))
    }
  }

  const handleOnChange = (e) => setValue(e.target.value)

  return (
    <TextInputField
      {...props}
      value={getValue()}
      onChange={handleOnChange}
    />
  )
})

export default JiraProjectsField
