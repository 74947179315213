import React from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import JiraProjectsField from '../fields/JiraProjectsField'

const JiraConfiguration = () => {
  return (
    <CardContainer className='h-full col-span-2'>
      <div className='flex flex-row mb-4'>
        <div className='flex-grow font-semibold type-large text-default'>
          Jira
        </div>
      </div>
      <div className='mt-1 type-default text-secondary'>
        <JiraProjectsField
          autoComplete='false'
          name='artifactConfiguration.jira'
          label='Jira Project(s)'
          helperText='If using multiple projects, separate with commas'
          placeholder='Jira project key...'
        />
      </div>
    </CardContainer>
  )
}

export default JiraConfiguration
