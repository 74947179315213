import React from 'react'
import { useFormikContext, getIn } from 'formik'
import { TextInputField } from '@toasttab/buffet-pui-forms'

const NullableTextInputField = ((props) => {
  const { setFieldValue, values } = useFormikContext()

  const getValue = () => {
    const formikVal = getIn(values, props.name)
    return formikVal ?? ''
  }

  const setValue = (inputVal) => {
    const val = inputVal === '' ? null : inputVal
    setFieldValue(props.name, val)
  }

  const handleOnChange = (e) => setValue(e.target.value)

  return (
    <TextInputField
      {...props}
      value={getValue()}
      onChange={handleOnChange}
    />
  )
})

export default NullableTextInputField
