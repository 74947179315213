import React from 'react'

// there must be a more react-like way to do this...

export function SnykRecommendation({ 
  info = info
}) {
  var urlMap = new Array()
  try {
    if (info.vulnerabilities && info.vulnerabilities.length > 0)
      for (let index = 0; index < info.vulnerabilities.length; index++) {
        urlMap.push({
          title: info.vulnerabilities[index].title,
          url: info.vulnerabilities[index].url,
          description: info.vulnerabilities[index].description,
          paths: info.vulnerabilities[index].paths 
        })
      }
  } catch (e) {
    console.log('Error getting the URL: ' + e)
  }
  
  const hasSynkUrls = urlMap.length > 0

  return (
  <>
  {hasSynkUrls ? snykURLs(urlMap) : ''}
  </>)
}

function snykURLs(listUrl) {
  var arrayOfURLs = []
  var titles = []
  var arrayOfPaths = []
  
  listUrl.forEach((element) => {
    element.paths.forEach((paths) => {
      paths.forEach((path) => {
        arrayOfPaths.push(
          <>
          <li>
          <p className='font-semibold type-default text-color-default'>
            {path['name']}
          </p>
          </li>
          </>
          )
      })

    })
    if (!titles.includes(element['title'])) {
      titles.push(element['title'])
      arrayOfURLs.push(
        <>
          <p className='font-bold type-default text-color-default'>
            {element['title']}
          </p>
          <li>
            <a href={element['url']}>{element['url']}</a>
          </li>
          <p className='space-y-4 font-normal type-default text-color-default'>
            {element['description']}
          </p>
        </>
      )
    } else {
      arrayOfURLs.push(
        <>
          <li>
            <a href={element['url']}>{element['url']}</a>
          </li>
        </>
      )
    }
  })
  return (
    <>
    <div className='space-y-4'>
     {arrayOfURLs}
    </div>
    <div className='space-y-4'>
    {arrayOfPaths != null 
      ? <div className='space-y-4'><p/><p className='font-bold type-default text-color-default'> Paths (to help with triaging): </p><li>{arrayOfPaths}</li><p/></div> 
      : <><div className='space-y-4'><p className='font-bold type-default text-color-default'>No path available</p><p/></div></>}
    </div>
    </>
  )
}