import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { BaseCard } from '@toasttab/buffet-pui-card'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Badge } from '@toasttab/buffet-pui-badge'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@toasttab/buffet-pui-modal'
import {
  ExpandMoreIcon,
  ExpandLessIcon,
  ChefsHatIcon,
  TimeClockIcon,
  DoneIcon,
} from '@toasttab/buffet-pui-icons'
import { Textarea } from '@toasttab/buffet-pui-text-area'
import { appContainerElementId } from '../../utils/constants'
import {
  ignoreRecommendation,
  buildIgnoreBody,
} from '../../queries'
import { Disclosure } from '@toasttab/buffet-pui-disclosure'
import { MenuDropdown, ListItem } from '@toasttab/buffet-pui-dropdowns'
import { SastRecommendation } from './Sast'
import { SnykRecommendation } from './Snyk'
import { JiraRecommendation, JiraSubmitModal } from './Jira'

function IgnoreModal({
  showIgnore = false,
  maturity = 0,
  repository,
  hash,
  source,
  guid,
  summary
}) {
  const [selectedIgnoreTTL, setSelectedIgnoreTTL] = useState(undefined)
  const [text, setText] = useState('')
  const [numberCharacters, setNumberCharacters] = useState(undefined)
  const { showErrorSnackBar } = useSnackBar()

  const queryClient = useQueryClient()
  const queryKey = `fetchRepoDetails-${repository}`

  const mutation = useMutation(ignoreRecommendation, {
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey)
      const snapshotOfPreviousRecommendations = queryClient.getQueryData(
        queryKey
      )
      queryClient.setQueryData(queryKey, (prev) => {
        prev.recommendations = prev.recommendations.filter(
          (recommendation) => recommendation.guid !== guid
        )
        return prev
      })

      return {
        snapshotOfPreviousRecommendations,
      }
    },
    onSuccess: (error, id, { snapshotOfPreviousRecommendations }) => {
      if (error.status != 200) {
        queryClient.setQueryData(queryKey, snapshotOfPreviousRecommendations)
        showErrorSnackBar(
          'The ignore recommendation failed. Please try again later'
        )
      }
      queryClient.invalidateQueries(queryKey)
    },
  })

  const handleMutation = React.useCallback(() => {
    mutation.mutate(buildIgnoreBody(selectedIgnoreTTL, repository, hash, text, summary))
  }, [repository, hash, text, summary, selectedIgnoreTTL])

  return (
    <>
      <Modal
        isOpen={Boolean(selectedIgnoreTTL)}
        appElement={document.body}
        parentSelector={() => {
          const element =
            document.getElementById(appContainerElementId) || document.body
          return element
        }}
      >
        <ModalHeader>
          {source == 'STATIC' ? 'Done summary' : 'Ignore reason'}
        </ModalHeader>
        <ModalBody>
          <Textarea
            name='Ignore reason'
            value={text}
            min={5}
            max={500}
            rows={7}
            disabled={false}
            invalid={text.length > 500 || text.length < 5}
            errorText='Input cannot be lesst than 5 characters or exceed 500 characters'
            onChange={(e) => {
              setText(e.target.value)
              setNumberCharacters(e.target.value.length)
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            variant='link'
            onClick={() => setSelectedIgnoreTTL(undefined)}
          >
            Cancel
          </Button>
          <Button
            iconLeft={<DoneIcon />}
            label='Submit'
            //Need to figure out how to improve condition handling for disabling the button
            disabled={
              numberCharacters == undefined ||
              numberCharacters < 5 ||
              numberCharacters > 500
            }
            onClick={handleMutation}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
      {showIgnore && maturity != 0 ? (
        <MenuDropdown
          preventCloseOnClick
          renderToggle={(props) => <Button {...props}>Ignore</Button>}
        >
          <ListItem
            onClick={() => setSelectedIgnoreTTL(7)}
            icon={<TimeClockIcon />}
            label='Ignore for 7 days'
          />
          <ListItem
            onClick={() => setSelectedIgnoreTTL(30)}
            icon={<TimeClockIcon />}
            label='Ignore for 30 days'
          />
          <ListItem
            onClick={() => setSelectedIgnoreTTL(90)}
            icon={<TimeClockIcon />}
            label='Ignore for 90 days'
          />
          <ListItem
            onClick={() => setSelectedIgnoreTTL(-1)}
            icon={<TimeClockIcon />}
            label={source == 'STATIC' ? 'Done' : 'Ignore forever'}
          />
        </MenuDropdown>
      ) : (
        <div />
      )}
    </>
  )
}

export function Recommendation({
  repositoryId,
  priority,
  summary = '',
  description,
  source,
  maturity,
  hash,
  guid,
  info,
  setIsExpanded,
  isExpanded,
}) {
  const descriptionSame = summary === description // use for determining if description needs to be displayed
  const hideCollapse = descriptionSame // useful if there's no extra data to show
  if (source == 'jira' || maturity <= 1) {
    info.ignoreable = false
  }
  const showIgnore = info.ignoreable === undefined || info.ignoreable == true
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div>
        <BaseCard
          style={{
            backgroundImage:
              'linear-gradient(to right, var(--color-green-smoothie) 5%, rgba(0, 0, 0, 0) 5%)',
          }}
        >
          <div className='flex items-end justify-end font-bold truncate text-heading-3' />
          <div className='flex flex-row space-x-2'>
            <Badge color={Badge.Color.warning} variant={Badge.Variant.statusLg}>
              <ChefsHatIcon className='pr-1' />
              {priority}
            </Badge>
            <p className='font-semibold type-default text-color-default'>
              {source}
            </p>
          </div>
          <hr className='my-4 border-0 border-b border-gray-50' />
          {summary && (
            <>
              <div className='flex p-2'>
                <div
                  className='flex-1 type-default text-color-default'
                  onClick={() => expandCard(setIsExpanded, guid)}
                  aria-expanded={isExpanded}
                  aria-label='show description'
                >
                  {summary}
                </div>
                <div hidden={hideCollapse}>
                  <IconButton
                    onClick={() => expandCard(setIsExpanded, guid)}
                    icon={
                      isExpanded ? (
                        <ExpandLessIcon size='xl' />
                      ) : (
                        <ExpandMoreIcon size='xl' />
                      )
                    }
                    className='text-color-secondary'
                    aria-expanded={isExpanded}
                    aria-label='show more'
                  />
                </div>
              </div>
              <Disclosure isOpen={isExpanded || hideCollapse} unmountOnClose>
                <Disclosure.Content>
                  <div className='type-default text-color-default'>
                    {/* There must be a better way to do this */}
                    {descriptionSame ||
                    ['sast', 'snyk', 'jira'].includes(source) ? null : (
                      <p>{description}</p>
                    )}
                    {source === 'sast' ? (
                      <SastRecommendation
                        summary={summary}
                        description = {description}
                        url = {info.url}
                        repository={repositoryId}
                      />
                    ) : (
                      ''
                    )}
                    {source === 'snyk' ? (
                      <SnykRecommendation
                        info={info}
                        repository={repositoryId}
                      />
                    ) : (
                      ''
                    )}
                    {source === 'jira' ? (
                      <JiraRecommendation
                        info={info}
                        repository={repositoryId}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </Disclosure.Content>
              </Disclosure>
            </>
          )}
          <div style={{ height: '30px' }} className='flex justify-left'>
            <JiraSubmitModal
              hash={hash}
              summary={summary}
              priority={priority}
              source={source}
              info={info}
              repository={repositoryId}
            />
            <IgnoreModal
              hash={hash}
              repository={repositoryId}
              maturity={maturity}
              showIgnore={showIgnore}
              source={source}
              guid={guid}
              summary = {summary}
            />
          </div>
        </BaseCard>
      </div>
    </>
  )
}

export default Recommendation

function expandCard(setIsExpanded, guid) {
  return setIsExpanded((prev) => (prev === guid ? null : guid))
}
