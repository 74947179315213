// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// import 'systemjs-webpack-interop/auto-public-path'

/**
 * Helper object used to ensure the runtime chunk name is matches the chunk named
 * by the webpackChunkName. We can't rely on typescript unforunately
 */
export const themes = {
  current: 'current',
  next: 'next'
}

/**
 * The webpackChunkName name has to match the themes name passed into the ThemeManager
 */

import(/* webpackChunkName: "current" */ `./current/index.css`)
import(/* webpackChunkName: "next" */ `./next/index.css`)
