import React, { Fragment, useState } from 'react'

import { Button } from '@toasttab/buffet-pui-buttons'
import MaturitySummary from './MaturitySummary'
import Configuration from './Configuration'
//Temp removing calls to the API
//import { fetchAllRepositories, fetchMineRepositories } from '../queries'
import Footer from './Footer'
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@toasttab/buffet-pui-tabs'

const RepositoryItem = ({ repository, selected, repoSetter }) => (
  <li className={selected ? 'selected' : ''}>
    <Link
      to={`/${repository.id}/builds/`}
      onClick={() => repoSetter(repository.name)}
    >
      {repository.name}{' '}
    </Link>
  </li>
)

const RepositoryListContainer = ({ repoSetter }) => {
  //const [myRepos, setAllRepos] = useState(false)
  //const { data, error } = useQuery(fetchAllRepositories)
  return (
    <div className='row align-items-center'>
      {/* <div className='py-3 md:py-5'>
        <p className='lead text-muted type-default text-default'>
          The Secure Coders of the month is "team name"
        </p>
      </div> */}
      {/* <div className='col-lg-5 pl-lg-5 pb-3 py-lg-5'>
        <Button onClick={fetchAllRepositories} type='submit'>
          Request Assistance
        </Button>
      </div> */}
      <Tabs className='w-full'>
        <TabList className='w-full'>
          <Tab>Recommendations</Tab>
          <Tab>Configuration</Tab>
          {/*<Tab>Knowledge Base and FAQ</Tab> */}
        </TabList>
        <TabPanels className='py-4'>
          <TabPanel>
            <MaturitySummary />
          </TabPanel>
          <TabPanel>
            <Configuration />
          </TabPanel>
          {/* <TabPanel>
            <Footer />
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </div>
  )
}

export default RepositoryListContainer
