import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import {
  fetchRepoConfig,
  updateArtifactConfigInRepo,
} from '../queries'
import { SubmitButton, CancelButton } from '@toasttab/buffet-pui-forms'
import { useQuery } from 'react-query'
import JiraConfiguration from './configuration/Jira'
import SastConfig from './configuration/Sast'
import SnykConfig from './configuration/Snyk'
import BaseConfig from './configuration/Base'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

const Configuration = () => {
  const { repositoryId } = useParams()
  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()

  const { data: repoConfig, refetch } = useQuery(
    `fetchRepoConfig-${repositoryId}`,
    () => fetchRepoConfig(repositoryId),
    {
      refetchInterval: false,
    }
  )

  const blankValues = {
    securityChampion: null,
    team: null,
    artifactConfiguration: {
      jira: null,
      snykEnabled: false,
      snykProjectPrefix: null,
      sastEnabled: false
    }
  }

  const validateForm = (values) => {
    const { sastEnabled, snykEnabled, snykProjectPrefix } = values.artifactConfiguration

    if ((sastEnabled || snykEnabled) && !snykProjectPrefix) {
      return {
        artifactConfiguration: {
          snykProjectPrefix: "Field is required for both snyk SAST and SCA"
        }
      }
    } else {
      return {}
    }
  }

  const formRef = useRef()

  useEffect(() => {
    formRef.current.resetForm()
  }, [repositoryId])

  return (
    <Formik
      innerRef={formRef}
      validate={validateForm}
      onSubmit={async (values) => {
        const response = await updateArtifactConfigInRepo(values, repositoryId)

        const data = await response.json().catch(() => ({}))
        if (response.ok) {
          refetch()
          showSuccessSnackBar('Configuration set successfully!')
        } else if (response.status == 500 || response.status == 404) {
          showErrorSnackBar('API Exception: ' + data.message)
        } else if (response.status < 500 && response.status > 400) {
          data.errors.map((error) => {
            showErrorSnackBar(error.message)
          })
        } else {
          showErrorSnackBar('Unexpected error.')
        }
      }}
      enableReinitialize={true}
      initialValues={repoConfig ?? blankValues}
    >
      {(formik) => {
        return (
          <Form
            id='artifactConfig'
            autoComplete='false'
            onSubmit={formik.handleSubmit}
          >
            <div className='grid grid-rows-3 max-w-6xl gap-4 mx-auto lg:gap-6 lg:grid-cols-3'>
              <BaseConfig />
              <JiraConfiguration />
              <SnykConfig />
              <SastConfig />
              <CancelButton
                className='flex-grow'
                disabled={formik.isSubmitting}
              >
                Revert
              </CancelButton>
              <SubmitButton
                className='flex-grow'
                disabled={formik.isSubmitting || !formik.dirty}
              >
                Save Changes
              </SubmitButton>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Configuration
