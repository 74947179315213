import React, { useState, useMemo } from 'react'
import { Button, LinkButton } from '@toasttab/buffet-pui-buttons'
import { ArrowCircleRightIcon, AddCircleIcon } from '@toasttab/buffet-pui-icons'
import { Select } from '@toasttab/buffet-pui-select'
import { updateArtifactConfigInRepo } from '../../queries'

export function DisplaySastApplications({
  info = info,
  repository = repository,
}) {
  const currentApplication =
    info.currentApplicationId === undefined
      ? 'unknown'
      : info.currentApplicationId.toString()
  const [selected, resultSet] = useState(currentApplication)

  const clickHandler = () => {
    updateSastConfig(repository.repositoryName, selected)
  }

  const defaultOption = {
    label: 'My application is not listed (add to SAST and come back)',
    value: 'unknown',
  }

  const givenApplications =
    info.applications === undefined
      ? []
      : info.applications
          .map((appData) => ({
            label: appData.applicationName,
            value: appData.applicationId.toString(),
          }))
          .concat(defaultOption)

  const options =
    givenApplications === undefined
      ? []
      : useMemo(
          () =>
            givenApplications.map((op) => ({
              ...op,
              result: selected === op.value,
              disabled:
                currentApplication != null
                  ? currentApplication.toString() === op.value
                  : false,
            })),
          [selected]
        )

  return (
    <>
      <div className='pt-6 space-x-4'>
        {options.length > 0 ? (
          <Select
            hideLabel
            name='mySastApp'
            onChange={resultSet}
            options={options}
            value={selected}
            inlineBlock
          />
        ) : (
          <></>
        )}

        {selected === 'unknown' ? (
          SastConfigURL(info.url)
        ) : (
          <Button
            onClick={clickHandler}
            iconLeft={<AddCircleIcon />}
            size='auto'
          >
            Save Configuration
          </Button>
        )}
      </div>
    </>
  )
}

export function SastRecommendation({
  description = description,
  repository = repository,
  url = url
}) {
   {/* Wee need more information from Snyk to show to developers */}
  const urlList = url?.split(",")

  return (
    <>
      <p className='space-y-4 font-normal type-default text-color-default'>
        {description}
      </p>
      { urlList && (
          <>
            <p className='space-y-4 font-semibold type-default text-color-default'>
              Open SAST issue details in New Tab
            </p>
            <p className='font-normal type-default text-color-default'>
              <ul>
                { urlList.map((url, i) => (
                    <li key={i}>
                      <LinkButton href={url} iconLeft={<ArrowCircleRightIcon />} size='auto' target='_blank'>
                        {url}
                      </LinkButton>
                    </li>
                  ))
                }
              </ul>
            </p>
          </>
        )
     }
    </>
  )
}

export function updateSastConfig(repositoryName, applicationId) {
  return updateArtifactConfigInRepo(repositoryName, {
    sastApplicationId: applicationId,
    sastEnabled: true,
  })
}
