import React from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'
import { HelpIcon } from '@toasttab/buffet-pui-icons'
import {
  LabelledToggleSwitchField
} from '@toasttab/buffet-pui-forms'

const SastConfig = () => {
  return (
    <>
      <CardContainer className='h-64 col-span-1'>
        <div className='flex flex-row mb-4 space-x-3'>
          <div className='flex-grow font-semibold type-large text-default'>
            SAST Configuration
          </div>
          <div/>
          <InfoTooltip
            icon={<HelpIcon />}
          >
            Enable or Disable SAST
          </InfoTooltip>
          <LabelledToggleSwitchField
            name='artifactConfiguration.sastEnabled'
            containerClassName='md:flex'
          />
        </div>
        <div className='flex flex-row mb-4 space-x-3'>
            <p className='space-y-4 font-semibold type-default text-color-default'>
            This repository is configured in Snyk Code.
            </p>
         </div>
      </CardContainer>
    </>
  )
}

export default SastConfig
