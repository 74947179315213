import React from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import NullableTextInputField from '../fields/NullableTextInputField'

const BaseConfig = () => {
  return (
    <>
      <CardContainer className='h-full row-span-2'>
        <div className='flex flex-row mb-4'>
          <div className='flex-grow font-semibold type-large text-default'>
            Base Configuration
          </div>
        </div>
        <div className='mt-1 type-default text-secondary'>
          <NullableTextInputField
            containerClassName='flex-grow'
            name='securityChampion'
            label='Security Champion'
            helperText="The Security Champion's email address"
            placeholder='someone@toasttab.com'
            required
          />
          <NullableTextInputField
            containerClassName='flex-grow'
            name='team'
            label='Team'
            helperText="The team responsible for this repo"
            placeholder='Application Security'
          />
        </div>
      </CardContainer>
    </>
  )
}


export default BaseConfig
