import { Fragment, useState } from 'react'
import { range } from 'lodash'
import { Recommendation } from './recommendation/Base'

export const groupRecommendationsByMaturity = (recommendations, currentMaturity) => {
  const [_, recommendationsWithMaturity] = recommendations.reduce((res, recommendation) => {
    if (!res[recommendation.maturity]) {
      res[recommendation.maturity] = []
    }
    res[recommendation.maturity].push(recommendation)

    return res
  }, []).reduce((res, list, m) => {
    const [prevMaturity, collection] = res
    const maturityRange = range(prevMaturity, m + 1, 1)
    collection.push([list, m, maturityRange])

    return [m + 1, collection]
  }, [currentMaturity, []])

  return recommendationsWithMaturity
}

const RecommendationList = ({ repositoryId, recommendations, currentMaturity }) => {
  const MAX_MATURITY_LEVEL = 5
  const [isExpanded, setIsExpanded] = useState()

  if (recommendations.length === 0) {
    return (
      <div>No recommendations, you're amazing.</div>
    )
  }

  const recommendationsWithMaturity = groupRecommendationsByMaturity(recommendations, currentMaturity)

  return (
    <div>
      {recommendationsWithMaturity.map(([list, maturity, maturityRange], i) => (
        <Fragment key={maturity}>
          <ul>
            {list.map((recommendation) => (
              <li key={recommendation.hash} className="mt-6">
                <Recommendation
                  isExpanded={recommendation.guid === isExpanded}
                  setIsExpanded={setIsExpanded}
                  repositoryId={repositoryId}
                  {...recommendation}
                />
              </li>
            ))}
          </ul>
          {maturityRange.map((m) => (
            <h6 key={m} className='my-5 type-headline-5 text-default'>
              Complete{' '}
              {m < MAX_MATURITY_LEVEL
                ? `tasks above for BISQ score ${m + 1}`
                : `the above additional recommendations to become AWESOME!`}
            </h6>
          ))}
        </Fragment>
      ))}
    </div>
  )
}

export default RecommendationList
