import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Formik } from 'formik'

import { Button, LinkButton } from '@toasttab/buffet-pui-buttons'
import { Modal } from '@toasttab/buffet-pui-modal'
import {
  TextareaField,
  TextInputField,
  SelectField,
} from '@toasttab/buffet-pui-forms'
import {
  TestKitchenIcon,
  PrepTimeIcon,
  FlameIcon,
  GuestIcon,
  SubtractIcon,
  ArrowDecreaseIcon,
  ArrowIncreaseIcon,
  ArrowCircleRightIcon,
} from '@toasttab/buffet-pui-icons'
import { Table, Body, Row, Cell } from '@toasttab/buffet-pui-table'
import { Image } from '@toasttab/buffet-pui-image'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { appContainerElementId } from '../../utils/constants'
import {
  fetchRepoConfig,
  createJiraTicket,
  ignoreRecommendation,
  buildIgnoreBody,
} from '../../queries'
import { Badge } from '@toasttab/buffet-pui-badge'
import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'

export function JiraRecommendation({ info = info }) {
  if (!info.issueDetails) return 'No issue details found.'

  const fields = info.issueDetails.fields
  const slaTarget = getSlaStatus(info.sla, fields.duedate)
  const priority = getDisplayPriority(fields.priority.name)

  return (
    <>
      <div className='p-4 space-x-4 space-y-4'>
        <Table>
          <Body>
            <Row>
              <Cell>Ticket Status</Cell>
              <Cell>
                <Badge
                  color={Badge.Color.neutral}
                  variant={Badge.Variant.statusLg}
                >
                  {fields.status.name}
                </Badge>
              </Cell>
            </Row>
            <Row>
              <Cell>Priority</Cell>
              <Cell>
                <Badge
                  color={priority.color}
                  variant={Badge.Variant.statusLg}
                  iconLeft={priority.icon}
                >
                  {fields.priority.name}
                </Badge>
              </Cell>
            </Row>
            <Row>
              <Cell>Assignee</Cell>
              <Cell>
                <Image
                  src={fields.assignee?.avatarUrls['32x32']}
                  showError={true}
                  errorComp={
                    <GuestIcon
                      className='mr-4 w-8 h-8 bg-gray-50 text-gray-75'
                      size='md'
                    />
                  }
                  className='pr-4'
                />

                {fields.assignee ? fields.assignee.displayName : 'Unassigned'}
              </Cell>
            </Row>
            <Row>
              <Cell>SLA Status</Cell>
              <Cell>
                <Badge
                  color={slaTarget.color}
                  variant={Badge.Variant.statusLg}
                  iconLeft={slaTarget.icon}
                >
                  {slaTarget.message}
                </Badge>
                <InfoTooltip placement='right-start'>
                  {priority.helperText}
                </InfoTooltip>
              </Cell>
            </Row>
            <Row>
              <Cell>Due</Cell>
              <Cell>
                <p>{slaTarget.due}</p>
              </Cell>
            </Row>
            <Row>
              <Cell>Description</Cell>
              <Cell>
                <ReactMarkdown
                  children={fields.description}
                  remarkPlugins={[remarkGfm, remarkBreaks]}
                />
              </Cell>
            </Row>
          </Body>
        </Table>
      </div>
    </>
  )
}

export function JiraSubmitModal({
  hash,
  source,
  priority,
  summary,
  info,
  repository,
  handleIgnore,
}) {
  const { data: configuration, isLoading } = useQuery(
    `fetchRepoConfig-${repository}`,
    () => fetchRepoConfig(repository),
    {
      refetchInterval: false,
    }
  )

  const { showErrorSnackBar, showSuccessSnackBar } = useSnackBar()
  const hideButton = info.releaseDetails || source == 'jira' || priority < 50

  const [isOpen, setIsOpen] = useState(false)
  const closeModal = () => setIsOpen(false)

  const queryClient = useQueryClient()
  const queryKey = `fetchRepoDetails-${repository}`

  const jiraPriority = getJiraPriority(priority)
  const jiraDescription = buildJiraDescription(repository, source, info)
  const jiraProject =
    configuration != null
      ? getJiraProject(configuration.artifactConfiguration)
      : ''
  const dueDate = buildDueDate(priority)

  const fieldMap = {
    summary: source + ": " + summary,
    labels: ['created-from-security-buddy', 'security', repository, source.replace(/\s/g, "-")],
    priority: jiraPriority,
    description: jiraDescription,
    dueDate: dueDate.toISOString(),
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        size='xxl'
        parentSelector={() => {
          const element =
            document.getElementById(appContainerElementId) || document.body
          return element
        }}
      >
        <Modal.Header>Create issue in Jira</Modal.Header>
        <Modal.Body>
          <Formik
            containerClassName='flex space-x-4'
            onSubmit={async (formik, { resetForm }) => {
              closeModal
              createJiraTicket(formik).then(async (response) => {
                const data = await response.json()
                if (response.ok == true) {
                  ignoreRecommendation(
                    buildIgnoreBody(365, repository, hash, 'JIRA:' + data.key)
                  )
                  showSuccessSnackBar(
                    <>
                      Jira ticket{' '}
                      <LinkButton
                        href={
                          'https://toasttab.atlassian.net/browse/' + data.key
                        }
                        variant='text-link'
                        className='-my-3 md:-my-2'
                      >
                        {data.key}
                      </LinkButton>{' '}
                      has been created!
                    </>
                  )
                  queryClient.invalidateQueries(queryKey)
                } else if (response.status == 500 || response.status == 404) {
                  showErrorSnackBar('API Exception: ' + "Unable to connect to Jira. Make sure that correct Jira Projects are listed in configuration.")
                } else if (response.status < 500 && response.status > 400) {
                  data.errors.map((error) => {
                    showErrorSnackBar(error.message)
                  })
                } else {
                  showErrorSnackBar('Unexpected error.')
                }
                resetForm({ values: '' })
              })
            }}
            initialValues={{
              repository: repository,
              jiraProject: jiraProject,
              summary: fieldMap.summary,
              priority: fieldMap.priority,
              dueDate: fieldMap.dueDate,
              description: fieldMap.description,
              hash: hash,
              labels: fieldMap.labels,
            }}
          >
            {(props) => (
              <form
                onSubmit={props.handleSubmit}
                id={hash + '-tojira'}
                className='p-4 space-y-4'
                autoComplete='false'
              >
                <div className='flex space-x-4'>
                  <TextInputField
                    name='jiraProject'
                    label='Jira Project'
                    helperText='Your Jira project'
                    placeholder='SECENG'
                  />
                  <SelectField
                    name='priority'
                    label='Priority'
                    helperText='Jira priority'
                    options={[
                      { label: 'Highest', value: 'Highest' },
                      { label: 'High', value: 'High' },
                      { label: 'Medium', value: 'Medium' },
                      { label: 'Low', value: 'Low' },
                      { label: 'Lowest', value: 'Lowest' },
                    ]}
                    disabled
                  />
                  <TextInputField
                    name='dueDate'
                    label='Due Date'
                    helperText='Calculated based on priority'
                    disabled
                  />
                </div>

                <TextInputField
                  containerClassName='flex-grow'
                  name='summary'
                  label='Summary'
                  helperText='The summary for the issue'
                  placeholder='Brief description of the issue...'
                />

                <TextareaField
                  containerClassName='flex-grow'
                  name='description'
                  label='Description'
                  helperText='A detailed description of the issue in Jira markdown'
                  placeholder='Enter a full description here, Jira markdown acceptable'
                />
              </form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='flex-grow sm:flex-none'
            onClick={closeModal}
            variant='link'
          >
            Close
          </Button>
          <Button
            className='flex-grow sm:flex-none'
            type='submit'
            form={hash + '-tojira'}
          >
            Submit to Jira
          </Button>
        </Modal.Footer>
      </Modal>
      {hideButton ? (
        info.issueDetails ? (
          <LinkButton
            href={
              'https://toasttab.atlassian.net/browse/' + info.issueDetails.key
            }
            iconLeft={<ArrowCircleRightIcon />}
            size='auto'
            target='_blank'
          >
            Open {info.issueDetails.key} in a new window
          </LinkButton>
        ) : (
          ''
        )
      ) : (
        <Button className='flex-grid mr-2' onClick={() => setIsOpen(true)}>
          Create issue...
        </Button>
      )}
    </>
  )
}

function getJiraPriority(score) {
  if (score >= 800) {
    return 'Highest'
  } else if (score >= 600 && score < 800) {
    return 'High'
  } else if (score >= 400 && score < 600) {
    return 'Medium'
  } else if (score >= 200 && score < 400) {
    return 'Low'
  } else {
    return 'Lowest'
  }
}

function getDisplayPriority(priority) {
  switch (priority) {
    case 'Highest':
      return {
        icon: <ArrowIncreaseIcon />,
        helperText: 'SLA of 5 business days',
        color: 'error',
      }
    case 'High':
      return {
        icon: <ArrowIncreaseIcon />,
        helperText: 'SLA of 10 business days',
        color: 'brand',
      }
    case 'Medium':
      return {
        icon: <SubtractIcon />,
        helperText: 'SLA of 20 business days',
        color: 'warning',
      }
    case 'Low':
      return {
        icon: <ArrowDecreaseIcon />,
        helperText: 'SLA of 125 business days',
        color: 'orchid',
      }
    case 'Lowest':
      return {
        icon: <ArrowDecreaseIcon />,
        helperText: 'SLA of 365 days',
        color: 'orchid',
      }
    default:
      return {}
  }
}

function buildJiraDescription(repository, source, info) {
  switch (source) {
    case 'snyk':
      return buildSnykDescription(repository, info)
    case 'sast':
      return buildSastDescription(repository, info)
    case 'STATIC':
      return 'See summary.'
    default:
      return 'No description available.'
  }
}

function buildSastDescription(repository, info) {
  const vulnData = info.vulnerabilityData
  if (vulnData) {
    return `*Link to Full Report:* Open in SAST - ${info.url}
---
*Repository:* ${repository}
*Category:* ${vulnData.category}
*Introduced Date:* ${vulnData.introducedDate}
*CWE:* ${vulnData.cwe}
*PCI Violation:* ${vulnData.pci3}
*File Location:* ${vulnData.primaryLocationFull}`
  } else {
    return `*Link to Application Group:* Open in SAST - ${info.url}`
  }
}

function buildSnykDescription(repository, info) {
  const vulnData = info.vulnerabilities
  if (vulnData) {
    if (vulnData.length > 0) {
      return `*Link to Vulnerability:* ${vulnData[0].url}
---
*Repository:* ${repository}
*Title:* ${vulnData[0].title}
*CVSS Score:* ${vulnData[0].cvssScore}
*Vulnerable Package:* ${vulnData[0].packageName}
*Current Version:* ${vulnData[0].packageVersion}
*Fixed in version(s):* ${vulnData[0].fixInfo.fixedIn}`
    } else {
      return 'Please see summary.'
    }
  } else {
    return 'Please see summary.'
  }
}

// Follow SLAs listed here:
// https://github.com/toasttab/system-docs/blob/main/security/vulnerability_reporting_and_mitigation.md#slas
function buildDueDate(score) {
  let today = new Date()
  if (score >= 800) {
    // 5 business days (7 calendar days)
    return today.addDays(7)
  } else if (score >= 600 && score < 800) {
    // 10 business days (14 calendar days)
    return today.addDays(14)
  } else if (score >= 400 && score < 600) {
    // 20 business days (28 calendar days)
    return today.addDays(28)
  } else if (score >= 200 && score < 400) {
    // 125 business days (180 calendar days)
    return today.addDays(180)
   } else {
    // No SLA in the vulnerability reporting but keeping it to have at least some due date on a ticket
    return today.addDays(365)
  }
}

function getJiraProject(config) {
  if (config.jira != null) {
    if (typeof config.jira === 'string') {
      return config.jira.split(',')[0]
    } else {
      return config.jira[0].project
    }
  }
}

function getSlaStatus(sla, dueDate) {
  if (!sla || !dueDate) {
    return {
      color: 'error',
      message: 'No Due Date',
      days: -1,
      icon: <FlameIcon />,
      due: 'Please set a due date for this issue in Jira',
    }
  } else if (sla.slaMissedByDays > 0) {
    return {
      color: 'error',
      message: 'SLA Missed',
      days: sla.slaMissedByDays,
      icon: <FlameIcon />,
      due:
        'Due ' +
        sla.slaMissedByDays +
        ' day(s) ago! (' +
        format(new Date(dueDate), Formats.date.medium, 'en-US') +
        ')',
    }
  } else if (sla.daysUntilDue > 14) {
    return {
      color: 'neutral',
      message: 'On Target',
      days: sla.daysUntilDue,
      icon: <PrepTimeIcon />,
      due: 'Issue is due on ' + format(new Date(dueDate), Formats.date.medium, 'en-US'),
    }
  } else {
    return {
      color: 'warning',
      message: 'In Danger',
      days: sla.daysUntilDue,
      icon: <TestKitchenIcon />,
      due:
        'Due in ' +
        sla.daysUntilDue +
        ' day(s). (' +
        format(new Date(dueDate), Formats.date.medium, 'en-US') +
        ')',
    }
  }
}

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf())
  date.setDate(date.getDate() + days)
  return date
}
