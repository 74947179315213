import { useQuery } from 'react-query'
import { useParams, useNavigate } from 'react-router-dom'
import ErrorBoundary from './ErrorBoundary'
import RecommendationList from './RecommendationList'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { fetchRepoDetails, fetchRepoConfig, forceRecommendationRefresh } from '../queries'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DownloadIcon } from '@toasttab/buffet-pui-icons'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'

const MaturitySummary = () => {
  const { showErrorSnackBar, showSuccessSnackBar, showSnackBar } = useSnackBar()
  const { repositoryId } = useParams()
  const navigate = useNavigate()
  const { data: repo, error, isLoading } = useQuery(
    `fetchRepoDetails-${repositoryId}`,
    () => fetchRepoDetails(null, { repositoryId }),
    {
      refetchInterval: false,
    }
  )
  const { data: artifactConfig } = useQuery(
    `fetchRepoConfig-${repositoryId}`,
    () => fetchRepoConfig(repositoryId),
    {
      refetchInterval: false,
    }
  )
  if (error && error.status === 404) {
    return (
      // temp solution until I fix issue with type:'cors' error which returns geenric not found error
      <div>
        <div>
          <p>"{repositoryId}" isn't set up for security evaluation yet.</p>
        </div>
        <div>
          <p>
            Please navigate to the "Configuration" tab to set up your
            repository.
          </p>
        </div>
      </div>
    )
  }
  if (repo != null) {
    var { recommendations = [] } = repo
    if (artifactConfig != null) {
      recommendations = recommendations.filter((rec) => {
        return !(rec.hash in artifactConfig.ignoredRecommendations) &&
          !rec.isIgnored &&
          rec.priority > 25
      })
    }

    if (isLoading) {
      return <MerryGoRound />
    }
    const refreshPage = () => {
      navigate(0);
    }
    //const ignoredRecs = Object.entries(artifactConfig.ignoredRecommendations)

    //Maturity Level is renamed into Baked In Security Quotient (BISQ) in the UI for convenience
    const clickHandler = () => {
      showSnackBar('Getting fresh data')
      forceRecommendationRefresh(repositoryId).then(
          async (response) => {
          if (response.repositoryName != null) {
          showSuccessSnackBar('Data updated successfully!')
          refreshPage() //still refreshes the whole thing :/
          } else {
            response.errors.map((error) => {
              showErrorSnackBar(error.message)
              })
            }
          }
        )
    }
    return (
      <ErrorBoundary>
        <div>
          <div className='space-y-8 gap-4'>
            <div className="type-headline-5 text-default flex space-x-3">
            <p>Last Updated: {new Date(repo.lastUpdated).toString()}</p>
            <Button
              onClick={clickHandler}
              iconLeft={<DownloadIcon />}
              size='auto'
              >
              Get fresh recommendations
            </Button>
            </div>
            <p className='type-headline-3 text-color-default'>
              Current Baked In Security Quotient (BISQ): {repo.maturity}
            </p>
            <RecommendationList
              recommendations={recommendations}
              repositoryId={repositoryId}
              currentMaturity={repo.maturity}
            />
          </div>
        </div>
      </ErrorBoundary>
    )
  } else {
    return (
      <div>
        <MerryGoRound />
        <div className='my-5 space-y-4'>
          <p className='type-headline-2 text-color-default'>
            {' '}
            "Retrieving cached recommendations for {repositoryId}".
          </p>
        </div>
        <div className='my-8 space-y-4 p-2'>
          <p className='type-headline-3 text-color-default text-brand-70'>
            If the data is not loading, here is a few things to check:
          </p>
          <p className='type-headline-5 text-color-default text-brand-50'>
            - Please make sure you are connected to Pritunl.
          </p>
          <p className='type-headline-5 text-color-default text-brand-50'>
            - Make sure the configuration for SCA, with project prefix, and SAST, are
            properly set. Configuration changes will refresh the recommendation generating
            process.
          </p>
          <p className='type-headline-5 text-color-default text-brand-50'>
            - This is usually a temporary issue but if nothing helps and you are
            still unable to retrieve the data, please contact the Application
            Security team.
          </p>
        </div>
      </div>
    )
  }
}

export default MaturitySummary
