let headers = {
  'Content-Type': 'application/json;charset=utf-8',
}
const host = 'https://security-buddy.eng.toasttab.com'
//const host = 'http://localhost:8080'


export const checkResponse = (response) => {
  if (response.redirected && !response.ok) {
    return fetch(response.url).then(checkResponse)
  }

  if (!response.ok) {
    response.message = `Error while executing request: ${response.url}. Status: ${response.status}`
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}

const toJson = (response) => {
  return checkResponse(response).then((r) => r.json())
}

export const fetchAllRepositories = () =>
  fetch(`${host}/artifacts`).then(toJson)

export const fetchRepoDetails = (_, params) =>
  fetch(`${host}/artifacts/${params.repositoryId}/recommendations`).then(toJson)

export const forceRecommendationRefresh = (repositoryName) =>
  fetch(
    `${host}/artifacts/${repositoryName}/recommendations?forceRefresh=true`
  ).then(toJson)

export const fetchRepoConfig = (repositoryName) =>
  fetch(`${host}/artifacts/${repositoryName}`).then(toJson)

export const ignoreRecommendation = (ignoreBody) => {
  return fetch(`${host}/artifacts/artifacts/ignore`, {
    method: 'POST',
    headers: headers,
    body: ignoreBody,
  })
}

export const getJiraProjects = () => {
  fetch(`${host}/integrations/jira/projects`).then(toJson)
}

export const updateRepoConfig = (updatedRepoConfig) => {
  return fetch(`${host}/artifacts/${updatedRepoConfig.repositoryName}`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(updatedRepoConfig),
  })
}

export function updateArtifactConfigInRepo(
  artifactConfigRequest,
  repositoryId
) {
  const modifiedRequest = { ...artifactConfigRequest, repositoryName: repositoryId }
  return updateRepoConfig(modifiedRequest)
}

export function createJiraTicket(formik) {
  let hash = formik.hash
  let repository = formik.repository
  delete formik.hash
  delete formik.repository
  return fetch(`${host}/integrations/jira/issues`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      hash: hash,
      repository: repository,
      issueDetails: formik,
    }),
  }).then((response) => {
    return response
  })
}

export function buildIgnoreBody(
  ignoreDaysValue,
  repositoryName,
  hash,
  ignoreReason,
  summary
) {
  let ignoreBody = JSON.stringify({
    repositoryName: repositoryName,
    hash: hash,
    ttl: parseInt(ignoreTTL(parseInt(ignoreDaysValue))),
    reason: ignoreReason,
    summary
  })
  return ignoreBody
}

function ignoreTTL(days) {
  if (days != -1) {
    var ttl = BigInt(Date.now()) + BigInt(days * 24 * 60 * 60 * 1000)
  } else var ttl = BigInt(-1)
  return ttl
}

export function buildAConfigUpdateBody(
  ignoreDaysValue,
  repositoryName,
  hash,
  ignoreReason
) {
  let ignoreBody = JSON.stringify({
    repositoryName: repositoryName,
    hash: hash,
    ttl: parseInt(ignoreTTL(parseInt(ignoreDaysValue))),
    reason: ignoreReason,
  })
  return ignoreBody
}
